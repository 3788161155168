@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: auto;
  font-weight: 400;
  src: local('Inter Regular'), local('Inter-Regular'),
       url("../fonts/Inter-Regular.woff2") format("woff2"),
       url("../fonts/Inter-Regular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: auto;
  font-weight: 400;
  src: local('Inter Italic'), local('Inter-Italic'),
       url("../fonts/Inter-Italic.woff2") format("woff2"),
       url("../fonts/Inter-Italic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: auto;
  font-weight: 600;
  src: local('Inter Medium'), local('Inter-Medium'),
       url("../fonts/Inter-Medium.woff2") format("woff2"),
       url("../fonts/Inter-Medium.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: auto;
  font-weight: 600;
  src: local('Inter MediumItalic'), local('Inter-MediumItalic'),
       url("../fonts/Inter-MediumItalic.woff2") format("woff2"),
       url("../fonts/Inter-MediumItalic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: auto;
  font-weight: 800;
  src: local('Inter Bold'), local('Inter-Bold'),
       url("../fonts/Inter-Bold.woff2") format("woff2"),
       url("../fonts/Inter-Bold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: auto;
  font-weight: 800;
  src: local('Inter BoldItalic'), local('Inter-BoldItalic'),
       url("../fonts/Inter-BoldItalic.woff2") format("woff2"),
       url("../fonts/Inter-BoldItalic.woff") format("woff");
  font-display: swap;
}

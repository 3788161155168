@charset "UTF-8";

/* Brand Color */
$brand-color: #5b2a86; /* {{ .Site.Params.themeColor | default #5b2a86 }}; */

/* Light theme color */
$light-background: #fff;
$light-background-secondary: #eaeaea;
$light-background-header: #fafafa;
$light-color: #222;
$light-color-variant: black;
$light-color-secondary: #999;
$light-color-tertiary: #767676;
$light-border-color: #dcdcdc;
$light-table-color: #dcdcdc;
$light-logo-color: #222;
$light-custom-header-bg: #f5edf5;

/* Dark theme colors */
$dark-background: #232425;
$dark-background-secondary: #3b3d42;
$dark-background-header: #1b1c1d;
$dark-color: #a9a9b3;
$dark-color-variant: white;
$dark-color-secondary: #b3b3bd;
$dark-color-tertiary: #999;
$dark-border-color: #4e4e57;
$dark-table-color: #4e4e57;
$dark-logo-color: #d6d6db; /* #fff; */
$dark-custom-header-bg: #1b1c1d;

$media-size-phone: "(max-width: 684px)";
$media-size-tablet: "(max-width: 900px)";

/* Variables for js, must be the same as these in @custom-media queries */
:root {
  --phoneWidth: (max-width: 684px);
  --tabletWidth: (max-width: 900px);
}

/* Content */
$max-width: 860px;

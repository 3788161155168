.centered-plain-content {
  table {
    /* border-collapse: collapse;
    margin: 25px auto;
    font-size: 0.9em;
    min-width: 400px;
    max-width: 100%; */

    th,
    td {
      // padding: 12px 15px;
    }

    thead {
      tr {
        text-align: left;

        th:first-child {
          min-width: 150px;
          @media #{$media-size-tablet} {
            min-width: 100px;
          }
          @media #{$media-size-phone} {
            min-width: 50px;
          }
        }
      }
    }

    tbody {
      tr {
        text-align: left;
        // color: red;

        td {
          
        }

        td:first-child {
          vertical-align: top;
          font-weight: bold;

          min-width: 150px;
          @media #{$media-size-tablet} {
            min-width: 100px;
          }
          @media #{$media-size-phone} {
            min-width: 50px;
          }
        }
      }
    }
  }
}

.m0 {
  // margin: 1px 0;
  margin: 0 0;
  padding: 1px 3px;
}

#custom-01 {
  padding-top: 3px;
  font-weight: bold;
}

/*
$media-size-phone: "(max-width: 684px)";
$media-size-tablet: "(max-width: 900px)";
*/
